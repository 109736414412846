
export type FormatDateVariant = "date" | "datetime" | "time";
export type TimeZoneName = "short" | "long";

export const eventTz = {
  timeZone: "Pacific/Auckland",
  locale: "en-NZ",
  setTz(tz: string, locale: string) {
    this.timeZone = tz;
    this.locale = locale;
  }
}

export type FormatType = 'time' | 'date' | 'datetime' | 'custom';
export interface FormatDateOptions extends Intl.DateTimeFormatOptions {
  locale: string;
  timeZone: string;
  showStartTime?: boolean;
}

export interface FormatDateRangeOptions extends FormatDateOptions {
  showEndTime?: boolean;
}

const convertParts = (dateString: string) => {
  return dateString.replace(/\s[APap][Mm]/g, match => match.toLowerCase().trim()).replace(/\sat/g, ",");
}

export function formatEventDate(inputDate: Date, type: FormatType = "date", options?: FormatDateOptions) {
  return formatDate(inputDate, type, { timeZone: eventTz.timeZone, locale: eventTz.locale, ...options });
}

export function formatDate(inputDate: Date, type: FormatType = "date", options: FormatDateOptions): string {

  const date = inputDate;

  // Soft fail
  if (!date) return "";

  const { locale, timeZone, dateStyle = 'short', weekday, showStartTime = true, timeZoneName, ...rest } = options || {};

  const timeFormatter = new Intl.DateTimeFormat(locale, {
    timeZone,
    hour: 'numeric',
    minute: 'numeric',
    ...rest
  });

  const formatOptions = getDateFormatOptions(dateStyle, weekday);

  const dateFormatter = new Intl.DateTimeFormat(locale, { timeZone, ...formatOptions, ...rest });

  let resultDateString: string;

  switch (type) {
    case 'custom':
      resultDateString = new Intl.DateTimeFormat(locale, {
        timeZone,
        weekday,
        ...rest
      }).format(date);
      break;
    case 'time':
      resultDateString = timeFormatter.format(date);
      break;
    case 'date':
      resultDateString = dateFormatter.format(date);
      break;
    case 'datetime':
      if (!showStartTime) {
        resultDateString = dateFormatter.format(date);
      } else {
        resultDateString = `${dateFormatter.format(date)}, ${timeFormatter.format(date)}`;
      }
      break;
    default:
      throw new Error('Invalid format type');
  }

  resultDateString = convertParts(resultDateString);

  return resultDateString;
}


function getDateFormatOptions(dateStyle: FormatDateOptions["dateStyle"] = "short", weekday: FormatDateOptions["weekday"]): Intl.DateTimeFormatOptions {
  return {
    short: {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      weekday
    },
    medium: {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      weekday
    },
    long: {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      weekday
    },
    full: {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      weekday
    }
  }[dateStyle] as Intl.DateTimeFormatOptions;
}